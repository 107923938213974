import React, { createContext, useState } from "react";

const defaultValues = {
  lang: "",
  setLang: () => {},
  cookie: true,
  setCookie: () => {},
  activeSite: "",
  setActiveSite: () => {},
  selected: "",
  setSelected: () => {},
};

export const GlobalContext = createContext(defaultValues);

export const GlobalProvider = ({ children }) => {
  const [cookie, setCookie] = useState(true);
  const [activeSite, setActiveSite] = useState("");
  const [lang, setLang] = useState("");
  const [selected, setSelected] = useState({ id: 1, name: "English" });

  return (
    <GlobalContext.Provider
      value={{
        ...defaultValues,
        cookie,
        setCookie,
        activeSite,
        setActiveSite,
        lang,
        setLang,
        selected,
        setSelected,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
