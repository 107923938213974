import React from "react";
import "@fontsource/inter";
import { GlobalProvider } from "./src/components/GlobalContext";
import "./src/styles/global.css";

export const wrapRootElement = ({ element }) => (
    <GlobalProvider>{element}</GlobalProvider>
);

export const onClientEntry = () => {
    const script = document.createElement("script");
    script.src =
        "https://mein.clickskeks.at/app.js?apiKey=2d7d454d4b26107ba5f29b4ea6ba85b297410f7b9c2fa274&domain=655cd71487fdb9459c78ec80";
    script.referrerPolicy = "origin"; // setting the referrer policy
    document.head.appendChild(script);

    const tagScript = document.createElement("script");
    const analyticsScript = document.createElement("script");

    tagScript.src = "https://www.googletagmanager.com/gtag/js?id=G-J73VHS043B";
    tagScript.async = true;
    document.head.appendChild(tagScript);

    analyticsScript.textContent = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            if (!["/imprint", "/de/imprint", "/privacy-notice", "/de/privacy-notice"].includes(window.location.pathname)) {
                gtag('config', 'G-J73VHS043B');
            }
            `;
    document.head.appendChild(analyticsScript);
};
